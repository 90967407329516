<template>
  <v-container style="padding-top:30px">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Top10样本
        </v-card-title>
        <v-card-text>
          <div v-for="(item,index) in top10">
            <a href="#" @click="toSample(item)">{{item.id}}</a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <p style="line-height: 2"><span style="font-size:18px;font-weight:800">基因名: </span>{{geneData.geneName}}</p>
    <v-divider style=";margin-bottom:30px"></v-divider>
    <v-card style="border: 1px solid #cccccc" elevation="0">
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基本信息</h2>
      </div>
      <div style="padding:30px">
        <table>
          <tr v-for="(value,key,index) in geneinfoKeys" :key="index">
            <td>{{ key }}</td>
            <td v-if="value === 'geneName'"><a :href="`https://www.genecards.org/cgi-bin/carddisp.pl?gene=${geneData[value]}`">{{ geneData[value] }}</a></td>
            <td v-else>{{ geneData[value] }}</td>
          </tr>
        </table>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">样本甲基化水平</h2>
      </div>
      <div style="display:flex;justify-content:center;width:100%">
        <div style="height:500px;width:1000px" id="histogram"></div>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基因表达和体能表型相关性</h2>
      </div>
      <div>
        <v-tabs vertical v-model="tab" @change="changeGeneChart(tab,'geneAndPhysicalFitness','sampleId',RNAExp)">
          <template v-for="(item,index) in tenPhysicalScore">
            <v-tab>
              {{item.text}}
            </v-tab>
            <v-tabs-items>
              <div style="display:flex;justify-content:center;width:100%">
                <div id="geneAndPhysicalFitness" :ref="`geneAndPhysicalFitness${tab}`"></div>
              </div>
            </v-tabs-items>
          </template>
        </v-tabs>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">甲基化和体能表型相关性</h2>
      </div>
      <div>
        <v-tabs vertical v-model="methylationTab" @change="changeGeneChart(methylationTab,'methylationAndPhysicalFitness','methylationKeys',MethylationExp)">
          <template v-for="(item,index) in tenPhysicalScore">
            <v-tab>
              {{item.text}}
            </v-tab>
            <v-tabs-items>
              <div style="display:flex;justify-content:center;width:100%">
                <div id="methylationAndPhysicalFitness"></div>
              </div>
            </v-tabs-items>
          </template>
        </v-tabs>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基因表达与甲基化水平相关性</h2>
      </div>
      <div style="display:flex;justify-content:center;width:100%">
        <div style="height:500px;width:800px" id="scatterPlot"></div>
      </div>
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">基因表达与甲基化水平对比图</h2>
      </div>
      <div>
        <v-tabs vertical v-model="tabValue" @change="changeGeneAndMethylationChart(tabValue)">
          <template v-for="(item,index) in tenPhysicalScore">
            <v-tab :style="{'margin-top':index === 0 ? '30px' : 0}">
              {{item.text}}
            </v-tab>
            <v-tabs-items>
              <div style="display:flex;justify-content:center">
                <div id="geneAndMethylation" style="width:500px;height:600px"></div>
                <div style="width:100px"></div>
                <div id="geneAndMethylation2" style="width:500px;height:550px"></div>
              </div>
            </v-tabs-items>
          </template>
        </v-tabs>
      </div>
      <div style="height:50px;background-color:#242a30;margin-top:30px">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">ceRNA关联关系图</h2>
      </div>
      <div style="display:flex;justify-content:center;width:100%">
        <div style="height:1000px;width:1000px" id="ceRNA"></div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import {transform} from 'echarts-stat'
import {getWorkflow} from "../JS/utils";
import {mapActions} from "vuex";
import {quantile} from 'simple-statistics'
import prepareBoxplotData from 'echarts/extension/dataTool/prepareBoxplotData'
import {permutationTest} from 'simple-statistics'
import cytoscape from 'cytoscape'
import async from "async";
import cyData from "../../public/cytoscapeData/cyData_1.json"
const ttest2 = require( '@stdlib/stats-ttest2' );
export default {
  name: "geneChildrenPage",
  data: () => ({
    geneData: {
      "geneName": "",
      "geneId": "",
      "geneType": "",
      "RNA": "",
      "start": "",
      "end": "",
      "transcriptomeDifferences": "",
      "differencesMethylationFitness": "",
      "length": "",
      "transcriptomeArr": [],
      "methylationArr": []
    },
    geneinfoKeys: {
      '基因名': 'geneName',
      '基因id': 'geneId',
      '基因类别': 'geneType',
      '染色体': 'RNA',
      '起始位点': 'start',
      '终止位点': 'end',
      '转录体能差异情况': 'transcriptomeDifferences',
      '甲基化体能差异情况': 'differencesMethylationFitness'
    },
    tenPhysicalScore: [//十个体能评分
      {
        text: '3000米跑',
        value: '3000RunningResult',
        value2: '3000RunRating',
      },
      {
        text: '握力',
        value: 'gripScore',
        value2: 'gripRating'
      },
      {
        text: '俯卧撑',
        value: 'pushUpScore',
        value2: 'pushUpRating'
      },
      {
        text: '胸前跪抛球',
        value: 'kneelingScore',
        value2: 'kneelingRating'
      },
      {
        text: '垂直纵跳',
        value: 'verticalScore',
        value2: 'verticalRating'
      },
      {
        text: '立定跳远',
        value: 'standingScore',
        value2: 'standingRating'
      },
      {
        text: '30米冲刺',
        value: '30RunningScore',
        value2: '30RunningRating'
      },
      {
        text: '六边形跳',
        value: 'hexagonScore',
        value2: 'hexagonRating'
      },
      {
        text: 'T型跑',
        value: 'TRunningScore',
        value2: 'TRunningRating'
      },
      {
        text: '坐位体前屈',
        value: 'sittingScore',
        value2: 'sittingRating'
      }
    ],
    tab: null,
    methylationTab:null,
    tabValue: null,
    dialog:false,
    top10:null,
    RNAExp:null,
    MethylationExp:null,
    cyData:cyData
  }),
  async mounted() {

  },
  async activated(){
    let that = this
    this.geneData = JSON.parse(that.$route.query.value)
    await this.getSessionData()
    await this.initHistogram()
    await this.initScatterPlot()
    await this.changeGeneChart(0,'geneAndPhysicalFitness','sampleId',this.RNAExp)
    await this.changeGeneChart(0,'methylationAndPhysicalFitness','methylationKeys',this.MethylationExp)
    await this.changeGeneAndMethylationChart(0)
    await this.initCy()
  },
  methods: {
    ...mapActions("snackbar", ["openSuccessSnackbar", "openFailureSnackbar", "openInfoSnackbar", "openSnackbar"]),
    ...mapActions("overlay", ["openOverlay", "closeOverlay"]),
    async initHistogram() {
      let that = this
      //搜索数据
      let sampleId = JSON.parse(sessionStorage.getItem('sampleId'))
      let expArr = this.RNAExp
      for (let i = 0; i < expArr.length; i++) {
        expArr[i] = Number(expArr[i])
      }
      let expData = this.arrSort(expArr)
      //直方图十个筒划分
      let everyColumn = Math.ceil((expData[expData.length - 1] - expData[0]) / 10)
      let data = [],
          min = expData[0],
          tenColumnsData = []
      let physicalFitness = JSON.parse(sessionStorage.getItem('physicalFitnessData'))
      let physicalFitnessData = []
      let c = this.RNAExp
      for (let i = 0; i < c.length; i++) {
        c[i] = Number(c[i])
      }
      for (let i = 0; i < physicalFitness.length; i++) {
        physicalFitnessData.push(physicalFitness[i]['submission'])
      }
      for (let i = 0; i < 10; i++) {
        data.push([min + i * everyColumn, min + (i + 1) * everyColumn, expData.filter(item => {
          return item >= min + i * everyColumn && item < min + (i + 1) * everyColumn
        }).length])
        let filterRes = expData.filter(item => {
          return item >= min + i * everyColumn && item < min + (i + 1) * everyColumn
        })
        if (filterRes) {
          let filters = this.arrSort(filterRes).reverse().slice(0, 10)
          let arr = []
          for (let j = 0; j < filters.length; j++) {
            let num = c.findIndex(item => {
              return item === filters[j]
            })
            if (num !== -1) {
              let a = physicalFitnessData.find(o => {
                return o.id === sampleId[num]
              })
              if (a) {
                arr.push(a)
              }
            }
          }
          tenColumnsData.push(arr)
        }
      }
      data = data.map(function (item, index) {
        return {
          value: item,
          itemStyle: {}
        };
      })

      var myChart = echarts.init(document.getElementById('histogram'))
      myChart.setOption(
          {
            tooltip: {},
            xAxis: {
              name: '平均甲基化水平',
              scale: true
            },
            yAxis: {
              name: '样本数',
            },
            series: [
              {
                type: 'custom',
                renderItem: function (params, api) {
                  var yValue = api.value(2);
                  var start = api.coord([api.value(0), yValue]);
                  var size = api.size([api.value(1) - api.value(0), yValue]);
                  var style = api.style();
                  return {
                    type: 'rect',
                    shape: {
                      x: start[0],
                      y: start[1],
                      width: size[0],
                      height: size[1]
                    },
                    style: style
                  };
                },
                label: {
                  show: true,
                  position: 'top'
                },
                // dimensions: ['from', 'to', 'profit'],
                encode: {
                  x: [0, 1],
                  y: 2,
                  // tooltip: [0, 1, 2],
                  // itemName: 3
                },
                data: data
              }
            ]
          }
      );
      myChart.on('click', async function (val) {
        that.openOverlay()
        that.top10 = tenColumnsData[val.dataIndex]
        that.dialog = true
        //点击时根据sampleId查询样本数据，然后页面跳转至样本搜索结果页面
        let sampleId = ''
        let res = await getWorkflow({
          "filterOption": {
            "filters": {
              "workflow": '62f0ac8864a77e00437abbbf',
              "submission.id": {"$eq": sampleId}
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 500
          }
        })
        //搜索到后处理数据，然后页面跳转
        // that.$router.push({path:'/sampleChildrenPage',query:{value:JSON.stringify(val)}})
        that.closeOverlay()
      })
    },
    async initScatterPlot() {
      echarts.registerTransform(transform.regression)
      //拿到RNA数据
      let RNAExp = this.RNAExp
      //获取RNA数据表头顺序
      let RNAKeys = JSON.parse(sessionStorage.getItem('sampleId'))
      //获取甲基化数据
      let MethylationExp = this.MethylationExp
      //获取甲基化数据表头顺序
      let MethylationKeys = JSON.parse(sessionStorage.getItem('methylationKeys'))
      //bed文件未给，所以先用RNA数据
      let data = []
      for (let i = 0; i < RNAKeys.length; i++) {
        let index = MethylationKeys.findIndex(o => {
          return o === RNAKeys[i]
        })
        if (index !== -1) {
          data.push([Number(RNAExp[i]), Number(MethylationExp[index])])
        } else {
          data.push([Number(RNAExp[i]), 0])
        }
      }
      var myChart = echarts.init(document.getElementById('scatterPlot'))
      myChart.setOption(
          {
            dataset: [
              {
                source: data
              },
              {
                transform: {
                  type: 'ecStat:regression'
                  // 'linear' by default.
                  // config: { method: 'linear', formulaOn: 'end'}
                }
              }
            ],
            title: {
              // text: 'Linear Regression',
              // subtext: 'By ecStat.regression',
              // sublink: 'https://github.com/ecomfe/echarts-stat',
              left: 'center'
            },
            legend: {
              bottom: 5
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              }
            },
            xAxis: {
              name: '基因表达量',
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              }
            },
            yAxis: {
              name: '甲基化水平',
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              }
            },
            series: [
              {
                name: 'scatter',
                type: 'scatter'
              },
              {
                name: 'line',
                type: 'line',
                datasetIndex: 1,
                symbolSize: 0.1,
                symbol: 'circle',
                label: {show: true, fontSize: 16},
                labelLayout: {dx: -20},
                encode: {label: 2, tooltip: 1}
              }
            ]
          }

      );
    },
    arrSort(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = 0; j < arr.length - i - 1; j++) {
          if (arr[j] > arr[j + 1]) {
            let hand = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = hand;
          }
        }
      }
      return arr;
    },
    async changeGeneChart(val,domId,sessionId,allData) {
      this.openOverlay()
      let exp = allData
      let key = this.tenPhysicalScore[val]['value']
      let physicalFitness = JSON.parse(sessionStorage.getItem('physicalFitnessData'))
      let physicalFitnessData = []
      for (let i = 0; i < physicalFitness.length; i++) {
        physicalFitnessData.push(physicalFitness[i]['submission'])
      }
      let RNATitle = JSON.parse(sessionStorage.getItem(sessionId))
      let data = []
      for (let i = 0; i < exp.length; i++) {
        let data1 = Number(exp[i])
        let data2 = physicalFitnessData.find(o => {
          return o['id'] === RNATitle[i]
        })
        let data3 = 0
        if (data2) {
          data3 = Number(data2[key])
        }
        data.push([data1, data3])
      }
      this.$nextTick(() => {
        echarts.registerTransform(transform.regression)
        let geneChart = document.getElementById(domId)
        if (geneChart) {
          geneChart.style.width = 970 + 'px'
          geneChart.style.height = 500 + 'px'
        }
        let myChart = echarts.getInstanceByDom(geneChart); //有的话就获取已有echarts实例的DOM节点。
        if (myChart == null) { // 如果不存在，就进行初始化。
          myChart = echarts.init(geneChart);
        }
        // var myChart = echarts.init(geneChart)
        myChart.setOption(
            {
              dataset: [
                {
                  source: data
                },
                {
                  transform: {
                    type: 'ecStat:regression'
                    // 'linear' by default.
                    // config: { method: 'linear', formulaOn: 'end'}
                  }
                }
              ],
              title: {
                // text: 'Linear Regression',
                // subtext: 'By ecStat.regression',
                // sublink: 'https://github.com/ecomfe/echarts-stat',
                left: 'center'
              },
              legend: {
                bottom: 5
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross'
                }
              },
              xAxis: {
                name: domId === 'methylationAndPhysicalFitness'?'基因甲基化水平':'基因表达量',
                splitLine: {
                  lineStyle: {
                    type: 'dashed'
                  }
                }
              },
              yAxis: {
                name: '体能评分',
                splitLine: {
                  lineStyle: {
                    type: 'dashed'
                  }
                }
              },
              series: [
                {
                  name: 'scatter',
                  type: 'scatter'
                },
                {
                  name: 'line',
                  type: 'line',
                  datasetIndex: 1,
                  symbolSize: 0.1,
                  symbol: 'circle',
                  label: {show: true, fontSize: 16},
                  labelLayout: {dx: -20},
                  encode: {label: 2, tooltip: 1}
                }
              ]
            }
        );
      })
      this.closeOverlay()
    },
    async changeGeneAndMethylationChart(val) {
      this.openOverlay()
      let that = this
      let RNAExp = this.RNAExp,
          MethylationExp = this.MethylationExp

      function a(option1, option2) {
        let key = that.tenPhysicalScore[val]['value2']
        let physicalFitness = JSON.parse(sessionStorage.getItem('physicalFitnessData'))
        let physicalFitnessData = []
        for (let i = 0; i < physicalFitness.length; i++) {
          physicalFitnessData.push(physicalFitness[i]['submission'])
        }
        let RNATitle = JSON.parse(sessionStorage.getItem(option2))
        let A = [],
            B = [],
            C = [],
            D = [],
            minA = 0,
            minD = 0,
            bool = false
        let data = []
        for (let i = 0; i < option1.length; i++) {
          let data2 = physicalFitnessData.find(o => {
            return o['id'] === RNATitle[i]
          })
          if (data2) {
            if (data2[key] === '优') {
              A.push(Number(option1[i]))
              if(i===0){
                minA = Number(option1[i])
              }else{
                if(Number(option1[i]) < minA){
                  minA = Number(option1[i])
                }
              }
            } else if (data2[key] === '良') {
              B.push(Number(option1[i]))
            } else if (data2[key] === '中') {
              C.push(Number(option1[i]))
            } else if (data2[key] === '差') {
              D.push(Number(option1[i]))
              if(i===0){
                bool = true
                minD = Number(option1[i])
              }else{
                if(Number(option1[i]) < minD){
                  minD = Number(option1[i])
                }
              }
            }
          } else {
            D.push(Number(option1[i]))
            if(bool){
              if(Number(option1[i]) < minD){
                minD = Number(option1[i])
              }
            }else{
              bool = true
              minD = Number(option1[i])
            }
          }
        }
        return {
          data:[A, B, C, D],
          minA:minA,
          minD:minD
        }
      }
      let b = a(RNAExp, 'sampleId')
      let c = a(MethylationExp, 'methylationKeys')
      let source1 = b['data']
      let source2 = c['data']
      let num1 = (source1[0].length && source1[0].length) ? ttest2(source1[0], source1[3]).pValue : null;
      let num2 = (source2[0].length && source2[0].length) ? ttest2(source2[0], source2[3]).pValue : null;
      function getPValue(num) {
        let pvalue = 0
        let p = Math.floor(Math.log(num) / Math.LN10);
        let n = num * Math.pow(10, -p);
        if (Number.isNaN(Math.log(num)) || Number.isFinite(Math.log(num)) || Number.isNaN(Math.pow(10, -p)) || Number.isFinite(Math.pow(10, -p)) || Number.isNaN(n)) {
          pvalue = num
        } else {
          pvalue = n + 'e' + p
        }
        return pvalue
      }

      let pvalue1 = num1
      let pvalue2 = num2
      let geneChart = document.getElementById('geneAndMethylation')
      let geneChart2 = document.getElementById('geneAndMethylation2')
      if (geneChart) {
        geneChart.style.width = 500 + 'px'
        geneChart.style.height = 550 + 'px'
      }
      let myChart = echarts.getInstanceByDom(geneChart2); //有的话就获取已有echarts实例的DOM节点。
      if (myChart == null) { // 如果不存在，就进行初始化。
        myChart = echarts.init(geneChart2);
      }
      // var myChart = echarts.init(geneChart)
      let myChart2 = echarts.init(geneChart);
      let x = ['优','良','中','差']
      myChart2.setOption(
          {
            title: [
              {
                text: '基因表达量',
                left: 'center'
              },
              {
                text: `优/差 p-value: ${pvalue1}`,
                borderColor: '#999',
                borderWidth: 1,
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 14,
                  lineHeight: 20
                },
                left: '20%',
                top: '93%'
              }
            ],
            dataset: [
              {
                // prettier-ignore
                source: source1
              },
              {
                transform: {
                  type: 'boxplot',
                  config: {itemNameFormatter: function(value){
                      return x[value['value']]
                    }}
                }
              },
              {
                fromDatasetIndex: 1,
                fromTransformResult: 1
              }
            ],
            tooltip: {
              trigger: 'item',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
              left: '10%',
              right: '10%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              boundaryGap: true,
              nameGap: 30,
              splitArea: {
                show: false
              },
              splitLine: {
                show: false
              }
            },
            yAxis: {
              type: 'value',
              // name: 'km/s minus 299,000',
              splitArea: {
                show: true
              }
            },
            series: [
              {
                name: 'boxplot',
                type: 'boxplot',
                datasetIndex: 1
              },
              {
                name: 'outlier',
                type: 'scatter',
                datasetIndex: 2
              }
            ]
          }
      );
      myChart.setOption(
          {
            title: [
              {
                text: '甲基化水平',
                left: 'center'
              },
              {
                text: `优/差 p-value: ${pvalue2}`,
                borderColor: '#999',
                borderWidth: 1,
                textStyle: {
                  fontWeight: 'normal',
                  fontSize: 14,
                  lineHeight: 20
                },
                left: '20%',
                top: '93%'
              }
            ],
            dataset: [
              {
                // prettier-ignore
                source: source2
              },
              {
                transform: {
                  type: 'boxplot',
                  config: {itemNameFormatter: function(value){
                      return x[value['value']]
                    }}
                }
              },
              {
                fromDatasetIndex: 1,
                fromTransformResult: 1
              }
            ],
            tooltip: {
              trigger: 'item',
              axisPointer: {
                type: 'shadow'
              }
            },
            grid: {
              left: '10%',
              right: '10%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              boundaryGap: true,
              nameGap: 30,
              splitArea: {
                show: false
              },
              splitLine: {
                show: false
              }
            },
            yAxis: {
              type: 'value',
              // name: 'km/s minus 299,000',
              splitArea: {
                show: true
              }
            },
            series: [
              {
                name: 'boxplot',
                type: 'boxplot',
                datasetIndex: 1
              },
              {
                name: 'outlier',
                type: 'scatter',
                datasetIndex: 2
              }
            ]
          }
      );

      this.closeOverlay()
    },
    toSample(item) {
      this.$router.push({path: '/sampleChildrenPage', query: {value: JSON.stringify(item)}})
    },
    async initCy() {
      let initData = {nodes: [], edges: []},
          nodeData = this.cyData.nodes,
          edgeData = this.cyData.links
      for (let i = 0; i < nodeData.length; i++) {
        initData.nodes.push({
          groups: 'nodes',
          data: nodeData[i]
        })
      }
      for (let i = 0; i < edgeData.length; i++) {
        initData.edges.push({
          groups: 'edges',
          data: edgeData[i]
        })
      }
      let cy = cytoscape({
        container: document.getElementById('ceRNA'),
        // initial viewport state:
        zoom: 1, // 图表的初始缩放级别.可以设置options.minZoom和options.maxZoom设置缩放级别的限制.
        pan: {x: 0, y: 0}, // 图表的初始平移位置.
        // interaction options:
        minZoom: 1e-50, // 图表缩放级别的最小界限.视口的缩放比例不能小于此缩放级别.
        maxZoom: 1e50, // 图表缩放级别的最大界限.视口的缩放比例不能大于此缩放级别.
        zoomingEnabled: true, // 是否通过用户事件和编程方式启用缩放图形.
        userZoomingEnabled: true, // 是否允许用户事件(例如鼠标滚轮,捏合缩放)缩放图形.对此缩放的编程更改不受此选项的影响.
        panningEnabled: true, // 是否通过用户事件和编程方式启用平移图形.
        userPanningEnabled: true, // 是否允许用户事件(例如拖动图形背景)平移图形.平移的程序化更改不受此选项的影响.
        boxSelectionEnabled: false, // 是否启用了框选择(即拖动框叠加,并将其释放为选择).如果启用,则用户必须点击以平移图表.
        selectionType: 'additive', // 一个字符串，指示用户输入的选择行为.对于'additive',用户进行的新选择将添加到当前所选元素的集合中.对于'single',用户做出的新选择成为当前所选元素的整个集合.
        touchTapThreshold: 8, // 非负整数,分别表示用户在轻击手势期间可以在触摸设备和桌面设备上移动的最大允许距离.这使得用户更容易点击.
                              // 这些值具有合理的默认值,因此建议不要更改这些选项,除非您有充分的理由这样做.大值几乎肯定会产生不良后果。
        desktopTapThreshold: 4, // 非负整数,分别表示用户在轻击手势期间可以在触摸设备和桌面设备上移动的最大允许距离.这使得用户更容易点击.
                                // 这些值具有合理的默认值,因此建议不要更改这些选项,除非您有充分的理由这样做.大值几乎肯定会产生不良后果。
        autolock: false, // 默认情况下是否应锁定节点(根本不可拖动,如果true覆盖单个节点状态).
        autoungrabify: false, // 默认情况下节点是否不允许被拾取(用户不可抓取,如果true覆盖单个节点状态).
        autounselectify: false, // 默认情况下节点是否允许被选择(不可变选择状态,如果true覆盖单个元素状态).
        // rendering options:
        headless: false, // true:空运行,不显示不需要容器容纳.false:显示需要容器容纳.
        styleEnabled: true, // 一个布尔值,指示是否应用样式.
        hideEdgesOnViewport: true, // 渲染提示,设置为true在渲染窗口时,不渲染边.例如,移动某个顶点时或缩放时,边信息会被临时隐藏,移动结束后,边信息会被执行一次渲染.由于性能增强,此选项现在基本上没有实际意义.
        hideLabelsOnViewport: true, // 渲染提示,当设置为true使渲染器在平移和缩放期间使用纹理而不是绘制元素时,使大图更具响应性.由于性能增强,此选项现在基本上没有实际意义.
        textureOnViewport: true, // 渲染提示,当设置为true使渲染器在平移和缩放期间使用纹理而不是绘制元素时,使大图更具响应性.由于性能增强,此选项现在基本上没有实际意义.
        motionBlur: true, // 渲染提示,设置为true使渲染器使用运动模糊效果使帧之间的过渡看起来更平滑.这可以增加大图的感知性能.由于性能增强,此选项现在基本上没有实际意义.
        motionBlurOpacity: 0.2, // 当motionBlur:true,此值控制运动模糊帧的不透明度.值越高,运动模糊效果越明显.由于性能增强,此选项现在基本上没有实际意义.
        wheelSensitivity: 1, // 缩放时更改滚轮灵敏度.这是一个乘法修饰符.因此,0到1之间的值会降低灵敏度(变焦较慢),而大于1的值会增加灵敏度(变焦更快).
        pixelRatio: 'auto', // 使用手动设置值覆盖屏幕像素比率(1.0建议,如果已设置).这可以通过减少需要渲染的有效区域来提高高密度显示器的性能,尽管在最近的浏览器版本中这是不太必要的.如果要使用硬件的实际像素比,可以设置pixelRatio: 'auto'(默认).
        elements: initData,
        // 用于设置图形样式的样式表.为方便起见,也可以将此选项指定为解析为样式表的promise.
        style: [
          {
            selector: 'node', style: {
              'label': 'data(id)',
              'color': 'black',
              'background-color': function(e){
                if(e._private.data.RNA_type==='miRNA'){
                  return '#ffc952'
                }else{
                  return '#ff7473'
                }
              }
            }
          },
          {
            selector: 'edge', style: {
              'target-arrow-color': '',//边缘源箭头的颜色
              'target-arrow-shape': 'triangle-backcurve',//边缘源箭头的形状
              'arrow-scale': 1.5,//缩放箭头大小；可以是任何 >= 0 的数字。
              'target-arrow-fill': 'filled', //边源箭头的填充状态；可能是filled或hollow。
              'curve-style': 'unbundled-bezier',
              'control-point-step-size': 80,//沿着从源到目标的垂直线，此值指定连续贝塞尔边缘之间的距离。
              'control-point-distance': '20px',//用手动值覆盖的单个值。因为它会覆盖步长，所以具有相同值的贝塞尔边缘会重叠。因此，如果需要，最好将其用作特定边的一次性值。
              // 'control-point-weight':'0.7',//沿从源到目标的线对控制点进行加权的单个值。该值通常在 [0, 1] 范围内，0 指向源节点，1 指向目标节点 - 但也可以使用更大或更小的值。
              'control-point-distances': ele => {
                let pos1 = ele.source().position().y;
                let pos2 = ele.target().position().y;
                let str = '' + Math.abs(pos2 - pos1) + 'px -' + Math.abs(pos2 - pos1) + 'px';
                return str;
              },
              // 'control-point-weights':'0.25 0.75.',
              'line-style': 'solid',
              'display': 'element',
            }
          }
        ],
        // 一个指定布局选项的普通对象.
        layout: {name: 'concentric'},
      })
    },
    async getSessionData() {
      if (!sessionStorage.getItem('sampleId')) {
        let res1 = await getWorkflow({
          "filterOption": {
            "filters": {
              "workflow": '62f5b7b57f7126003ed01d71',
              "submission.id": {"$eq": 'sampleId'}
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 1
          }
        })
        sessionStorage.setItem('sampleId', JSON.stringify(res1.results[0]['submission']['data']))
      }
      if (!sessionStorage.getItem('methylationKeys')) {
        let res1 = await getWorkflow({
          "filterOption": {
            "filters": {
              "workflow": '62f5b7b57f7126003ed01d71',
              "submission.id": {"$eq": 'methylationKeys'}
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 1
          }
        })
        sessionStorage.setItem('methylationKeys', JSON.stringify(res1.results[0]['submission']['data']))
      }
      //获取RNA数据
      let res1 = await getWorkflow({
        "filterOption": {
          "filters": {
            "workflow": '62f0b71564a77e00437abcc0',
            "submission.geneId": {"$eq": this.geneData.geneName}
          },
          "group": '61de80fa3f6359003ec98d55',
          "limit": 1
        }
      })
      this.RNAExp = res1.results[0]['submission']['exp'].split(',')
      //获取甲基化数据
      let res2 = await getWorkflow({
        "filterOption": {
          "filters": {
            "workflow": '62ff246b181352002c09e5b5',
            "submission.geneId": {"$eq": this.geneData.geneName}
          },
          "group": '61de80fa3f6359003ec98d55',
          "limit": 1
        }
      })
      this.MethylationExp = res2.results[0]['submission']['exp'].split(',')
      let res3 = await getWorkflow({
        "filterOption": {
          "filters": {
            "workflow": '63071b2eb87c19002c130c00',
            "submission.geneName": {"$eq": this.geneData.geneName}
          },
          "group": '61de80fa3f6359003ec98d55',
          "limit": 1
        }
      })
      if(res3.results.length){
        this.cyData = JSON.parse(res3.results[0]['submission']['cyData'])
      }else{
        this.cyData = cyData
      }
    },
    moneySplit(val) {
      if (!val) return val;
      let logo = ""; // 用于记录是正值还是负值
      let num = val;
      if (Number(val) < 0) {
        logo = "-";
        num = val.split('-')[1]; // 将负数转正,如果不转正，则下面获取它的length时，会由于有个负号，使得长度+1，最终加逗号位置出错
      }
      const result = num.split("");// 将数字转化为了数组，便于使用数组中的splice方法插入逗号
      let position = result.indexOf(".");// 获取小数点的位置，根据有无小数点确定position最终值进入添加逗号环节
      position = position !== -1 ? position : result.length;// 因为只需考虑整数部分插入逗号，所以需要考虑有没有逗号。有逗号则不等于-1，减去逗号位置，则是下标0~position就是整数部分；若不是小数，则num原本就是整数，直接取其length即可
      while (position > 3) {// 只要下标值大于2，说明前面还可以插入“,”，则继续循环
        position -= 3;// 下标前移3位，然后在这个下标对应的元素后面插入逗号
        result.splice(position, 0, ",");
      }
      return logo + result.join("");// 数组转换为字符串,前面+logo，若为负数则拼接个符号，否则拼接空字符
    }
  }
}
</script>

<style scoped>
tr {
  border-bottom: 1px solid #e4e7ed;
}
table {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #606266;
  border-collapse: collapse;
}

table thead {
  color: #909399;
  font-weight: 500;
}

table th,
table tr {
  background-color: #fff;
}
table td {
  padding: 12px 5px;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
